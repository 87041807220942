import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { MasterToolChainProfileResource } from '@endorlabs/endor-core/MasterToolChainProfile';
import {
  IconArrowRight,
  RowStack,
  TreeColumnListPanel,
  TreeColumnPanel,
  TreeColumns,
} from '@endorlabs/ui-common';

import { TOOL_DEFINITIONS, TOOLCHAIN_DEFINITIONS } from '../../constants';
import { OSLabelMessages } from '../../locales';
import { ToolchainToolInfo } from '../../types';
import { getToolchainLabel, getToolchainToolLabel } from '../../utils';
import {
  FormToolchainToolInfo,
  ToolchainToolInfoBase,
} from '../FormToolchainToolInfo';
import { ToolChainSummary } from '../ToolChainSummary';

export const ToolchainToolInfoSection = ({
  isLoading,
  masterToolChainProfile,
  onAddTool,
  onRemoveTool,
  tools,
}: {
  isLoading?: boolean;
  masterToolChainProfile?: MasterToolChainProfileResource;
  onAddTool: (tool: ToolchainToolInfo) => void;
  onRemoveTool: (tool: ToolchainToolInfo) => void;
  tools: ToolchainToolInfo[];
}) => {
  const { formatMessage: fm } = useIntl();
  const { space } = useTheme();

  // Base state for the ToolchainToolInfo
  const [state, setState] = useState<Partial<ToolchainToolInfoBase>>({
    os: undefined,
    arch: undefined,
    toolchain: undefined,
    tool: undefined,
  });

  // Options for the ToolchainToolInfo, built from the master tool chain
  const options = useMemo(() => {
    const os: { key: string; label: string }[] = [];
    const arch: { key: string; label: string }[] = [];

    const toolchain: { key: string; label: ReactNode }[] =
      TOOLCHAIN_DEFINITIONS.map(({ toolchain }) => ({
        key: toolchain,
        label: getToolchainLabel({ toolchain }),
      }));

    const tool = TOOL_DEFINITIONS.filter(
      (td) => td.toolchain === state.toolchain
    ).map((t) => ({
      key: t.tool,
      label: getToolchainToolLabel(t),
    }));

    for (const osKey of Object.keys(masterToolChainProfile?.spec.os ?? {})) {
      const osLabel = OSLabelMessages[osKey]
        ? fm(OSLabelMessages[osKey])
        : osKey;
      os.push({ key: osKey, label: osLabel });

      if (osKey === state.os) {
        const archKeys = Object.keys(
          masterToolChainProfile?.spec.os?.[osKey].arch ?? {}
        );

        for (const archKey of archKeys) {
          arch.push({ key: archKey, label: archKey });
        }
      }
    }

    os.sort((a, b) => a.label.localeCompare(b.label));
    arch.sort((a, b) => a.label.localeCompare(b.label));
    toolchain.sort((a, b) => a.key.localeCompare(b.key));

    return { os, arch, toolchain, tool };
  }, [fm, masterToolChainProfile?.spec.os, state.os, state.toolchain]);

  return (
    <RowStack
      spacing={space.md}
      width="100%"
      alignItems="stretch"
      flexWrap="nowrap"
      minHeight={600}
    >
      <Card
        elevation={0}
        variant="outlined"
        sx={{ flexGrow: 1, minHeight: '100%' }}
      >
        <TreeColumns>
          <TreeColumnListPanel
            title={<FM defaultMessage="OS" />}
            options={options.os}
            selected={state.os}
            onSelectedChange={(key) => setState({ os: key })}
          />

          {state.os && (
            <TreeColumnListPanel
              title={<FM defaultMessage="Architecture" />}
              options={options.arch}
              selected={state.arch}
              onSelectedChange={(key) => setState({ os: state.os, arch: key })}
            />
          )}

          {state.arch && (
            <TreeColumnListPanel
              title={<FM defaultMessage="Toolchains" />}
              options={options.toolchain}
              selected={state.toolchain}
              onSelectedChange={(key) =>
                setState({
                  os: state.os,
                  arch: state.arch,
                  toolchain: key,
                })
              }
            />
          )}

          {state.toolchain && (
            <TreeColumnListPanel
              title={<FM defaultMessage="Tools" />}
              options={options.tool}
              selected={state.tool}
              onSelectedChange={(key) =>
                setState({
                  os: state.os,
                  arch: state.arch,
                  toolchain: state.toolchain,
                  tool: key,
                })
              }
            />
          )}

          {state.tool && (
            <TreeColumnPanel title={<FM defaultMessage="Name" />}>
              <Box minWidth={300} width="100%" padding={2}>
                <FormToolchainToolInfo
                  base={state as ToolchainToolInfoBase}
                  isLoading={isLoading}
                  masterToolChainProfile={masterToolChainProfile}
                  tools={tools}
                  onSubmit={onAddTool}
                />
              </Box>
            </TreeColumnPanel>
          )}

          {!state.tool && (
            <TreeColumnPanel title={<>&nbsp;</>}>
              {!state.os && (
                // Handle: empty/initial state
                <RowStack sx={{ color: 'text.secondary', marginTop: 4 }}>
                  <IconArrowRight sx={{ transform: 'rotate(180deg)' }} />
                  <Typography color="text.secondary">
                    <FM defaultMessage="Select an OS to get started" />
                  </Typography>
                </RowStack>
              )}
            </TreeColumnPanel>
          )}
        </TreeColumns>
      </Card>

      <Card
        elevation={0}
        variant="outlined"
        sx={{ flexShrink: 0, minHeight: '100%', width: 350 }}
      >
        <CardHeader title="Toolchain Profile" />
        <CardContent>
          <ToolChainSummary
            isLoading={isLoading}
            tools={tools}
            onDelete={onRemoveTool}
          />
        </CardContent>
      </Card>
    </RowStack>
  );
};
