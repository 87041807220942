import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField } from '@mui/material';
import { isMatch as _isMatch } from 'lodash-es';
import { useEffect, useState } from 'react';
import { ErrorOption } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { MasterToolChainProfileResource } from '@endorlabs/endor-core/MasterToolChainProfile';
import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import { RowStack } from '@endorlabs/ui-common';

import { ToolchainToolInfo } from '../../types';
import {
  fromToolchainToolInfo,
  getToolchainToolInfoMatch,
  toToolchainToolInfo,
} from '../../utils';
import { ToolchainToolInfoSection } from './ToolchainToolInfoSection';

export type FormUpsertScanProfileProps = {
  isLoading?: boolean;
  namespace: string;
  onSubmit: (data: ScanProfileResource) => void;
  scanProfile?: ScanProfileResource;
  masterToolChainProfile?: MasterToolChainProfileResource;
};

export const FormUpsertScanProfile = ({
  isLoading,
  masterToolChainProfile,
  onSubmit,
  scanProfile,
}: FormUpsertScanProfileProps) => {
  const { formatMessage: fm } = useIntl();

  const [localErrors, setLocalErrors] = useState<Record<string, ErrorOption>>();
  const [name, setName] = useState('');
  const [tools, setTools] = useState<ToolchainToolInfo[]>([]);

  // initialize
  useEffect(() => {
    const tools = toToolchainToolInfo(scanProfile?.spec.toolchain_profile);

    setName(scanProfile?.meta.name ?? '');
    setTools(tools);
  }, [scanProfile]);

  const handleAddTool = (tool: ToolchainToolInfo) => {
    const next = [...tools];

    const current = getToolchainToolInfoMatch(tools, tool);
    if (current) {
      const ix = tools.indexOf(current);
      next[ix] = tool;
    } else {
      next.push(tool);
    }

    setTools(next);
  };

  const handleRemoveTool = (tool: ToolchainToolInfo) => {
    setTools((value) => value.filter((v) => !_isMatch(v, tool)));
  };

  const handleSubmit = () => {
    setLocalErrors(undefined);

    if (!name) {
      setLocalErrors({
        name: {
          message: fm({ defaultMessage: 'Scan Profile name is required' }),
          type: 'required',
        },
      });
      return;
    }

    const result = fromToolchainToolInfo(tools);

    const updated = {
      ...scanProfile,
      meta: {
        ...scanProfile?.meta,
        name,
      },
      spec: {
        toolchain_profile: result.toolchain,
      },
    } as ScanProfileResource;

    onSubmit(updated);
  };

  const isEdit = !!scanProfile;

  return (
    <Stack gap={4}>
      <RowStack justifyContent="space-between">
        <Box minWidth={400}>
          <TextField
            error={!!localErrors?.name}
            helperText={localErrors?.name.message}
            label={fm({
              defaultMessage: 'Scan Profile Name',
            })}
            onChange={(event) => setName(event.target.value)}
            placeholder={fm({
              defaultMessage: 'Enter a name for the Scan Profile.',
            })}
            required
            value={name}
          />
        </Box>

        <LoadingButton
          loading={isLoading}
          onClick={handleSubmit}
          variant="contained"
        >
          {isEdit ? (
            <FM defaultMessage="Save Scan Profile" />
          ) : (
            <FM defaultMessage="Create Scan Profile" />
          )}
        </LoadingButton>
      </RowStack>

      <ToolchainToolInfoSection
        isLoading={isLoading}
        masterToolChainProfile={masterToolChainProfile}
        onAddTool={handleAddTool}
        onRemoveTool={handleRemoveTool}
        tools={tools}
      />
    </Stack>
  );
};
