import { ProductTourInputData } from '../types';

/**
 * Identifiers for specific records needed by the product tour
 */
const staging: ProductTourInputData = {
  assuredPackageVersionName: 'org.apache.commons:commons-text',
  findingsProjectName: 'https://github.com/endorlabs/acme-monorepo.git',
  remediationsDependencyName: 'cryptography',
  tourTenant: 'demo-trial',
  vulnFindingName:
    'GHSA-93m7-c69f-5cfj: xmlquery lacks check for whether LoadURL response is in XML format, causing denial of service',
};

// Using the same names for staging & prod
const TourDataConfig = {
  staging,
  production: { ...staging },
};

export const getTourDataConfig = () => {
  return process.env.NODE_ENV === 'production'
    ? TourDataConfig.production
    : TourDataConfig.staging;
};
