import { Grid, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';
import {
  ButtonDestructive,
  ButtonSecondary,
  ControlledCodeEditorField,
} from '@endorlabs/ui-common';

import { FormUpsertSASTRuleFieldValues } from './types';

export const FormUpsertEditSASTRule = ({
  resource,
  onDelete,
  onDisable,
}: {
  resource: SemgrepRulesResource;
  onDelete: ({ uuid, namespace }: { uuid: string; namespace: string }) => void;
  onDisable: ({ resource }: { resource: SemgrepRulesResource }) => void;
}) => {
  const formMethods = useForm<FormUpsertSASTRuleFieldValues>({
    defaultValues: {
      spec: {
        yaml: resource?.spec.yaml ?? '',
      },
    },
  });
  const { control } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form id="sastRuleEditForm">
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <ControlledCodeEditorField
              key="sast-rule-field"
              codeEditorProps={{
                downloadProps: {
                  filename: `${resource?.spec?.rule?.id ?? 'sast-rule'}.yaml`,
                  filetype: 'yaml',
                },
                editorOptions: { lineWrapping: true },
                language: 'text/x-yaml',
                height: 'inherit',
                width: 'inherit',
                title: `${resource?.spec?.rule?.id ?? 'SAST Rule'}`,
              }}
              control={control}
              name="spec.yaml"
              required
              readOnly={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={4} justifyContent="flex-end">
              <ButtonDestructive
                type="button"
                onClick={() =>
                  onDelete({
                    uuid: resource.uuid,
                    namespace: resource.tenant_meta.namespace,
                  })
                }
              >
                <FM defaultMessage="Delete Rule" />
              </ButtonDestructive>
              <ButtonSecondary
                type="button"
                onClick={() => onDisable({ resource })}
              >
                <FM defaultMessage="Disable Rule" />
              </ButtonSecondary>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
