import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  ErrorOption,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { ControlledTextField, IconTrash, RowStack } from '@endorlabs/ui-common';

import { ToolInfoType } from '../../types';
import { getToolchainToolInfoMatch } from '../../utils';
import { CUSTOM_VERSION_KEY } from './constants';
import {
  FormToolchainToolInfoProps,
  ToolchainToolInfoFieldValues,
  ToolchainToolInfoSupportedVersionOption,
} from './types';
import { getDefaultValues } from './utils';

export const FormToolchainToolInfo = ({
  base,
  isLoading,
  onSubmit,
  tools,
  masterToolChainProfile,
}: FormToolchainToolInfoProps) => {
  const formMethods = useForm<ToolchainToolInfoFieldValues>({});

  const { handleSubmit: hookFormSubmit, reset } = formMethods;

  const [localError, setLocalError] = useState<
    (ErrorOption & { details?: string }) | undefined
  >();

  const { hasCurrentVersion, hasSupportedVersions, supportedVersions, tool } =
    useMemo(() => {
      return getDefaultValues({ base, masterToolChainProfile, tools });
    }, [base, masterToolChainProfile, tools]);

  useEffect(() => {
    // Reset errors on data change
    setLocalError(undefined);
    reset(tool);
  }, [reset, tool]);

  // Validation before create
  const onBeforeSubmit = (tool: ToolchainToolInfoFieldValues) => {
    setLocalError(undefined);

    if (tool.type === ToolInfoType.ToolChainVersion) {
      // If defining a custom version, return directly
      if (tool.value.key === CUSTOM_VERSION_KEY) {
        onSubmit({ ...tool, value: { version: tool.value.version } });
        return;
      }

      // If selecting a supported version, find and return
      const target = supportedVersions.find((sv) => sv.key === tool.value.key);
      if (target) {
        onSubmit({
          ...tool,
          value: { version: target.version },
        });

        return;
      }

      setLocalError({
        message: `Unable to add tool`,
        details: 'Selected version is not found',
      });
      return;
    }

    // For other tool types, prevent adding a duplicate instance of the tool
    const current = getToolchainToolInfoMatch(tools, tool);
    if (current) {
      setLocalError({
        message: `Unable to add tool "${tool.value.key}"`,
        details: 'Tool already exists in toolchain',
      });
      return;
    }

    onSubmit(tool);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id="FormUpsertScanProfileTool"
        noValidate
        onSubmit={hookFormSubmit(onBeforeSubmit)}
      >
        <Stack gap={4}>
          {tool.type === ToolInfoType.ToolList ? (
            <ToolListFields />
          ) : tool.type === ToolInfoType.ToolChainVersionMap ? (
            <ToolVersionMapFields />
          ) : hasSupportedVersions ? (
            <ToolVersionFields supportedVersions={supportedVersions} />
          ) : (
            <CustomToolVersionFields />
          )}

          {localError?.message && (
            <Alert severity="error">
              <AlertTitle>{localError.message}</AlertTitle>
              {localError.details}
            </Alert>
          )}

          <Box>
            <LoadingButton loading={isLoading} type="submit" variant="outlined">
              {hasCurrentVersion ? (
                <FM defaultMessage="Update profile" />
              ) : (
                <FM defaultMessage="Add to profile" />
              )}
            </LoadingButton>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};

const ToolVersionFields = ({
  supportedVersions,
}: {
  supportedVersions: ToolchainToolInfoSupportedVersionOption[];
}) => {
  const { control, watch } = useFormContext<ToolchainToolInfoFieldValues>();
  const selectedKeyValue = watch('value.key');

  return (
    <Stack
      gap={4}
      sx={{
        '& .MuiFormGroup-root': {
          gap: 2,
          marginLeft: -0.5,
        },
        '& .MuiRadio-root': {
          paddingLeft: 0,
        },
      }}
    >
      <Controller
        control={control}
        defaultValue=""
        name="value.key"
        render={({ field, fieldState }) => (
          <FormControl error={!!fieldState.error}>
            <RadioGroup {...field}>
              {supportedVersions.map((sv) => (
                <FormControlLabel
                  key={sv.key}
                  value={sv.key}
                  control={<Radio />}
                  label={sv.label}
                />
              ))}
            </RadioGroup>

            {fieldState.error?.message && (
              <FormHelperText sx={{ marginLeft: 0 }}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />

      {selectedKeyValue === CUSTOM_VERSION_KEY && <CustomToolVersionFields />}
    </Stack>
  );
};

const CustomToolVersionFields = () => {
  const { control, watch, setValue } =
    useFormContext<ToolchainToolInfoFieldValues>();

  const urls = watch('value.version.urls');
  const urlCount = Math.max(1, urls?.length ?? 0);

  return (
    <Stack gap={4}>
      <ControlledTextField
        control={control}
        defaultValue=""
        name="value.version.name"
        label="Name"
        required
        helperText="This is the version number."
      />

      <Box>
        <InputLabel required>
          <FM defaultMessage="URL" />
        </InputLabel>

        <Stack gap={2}>
          {Array.from({ length: urlCount }, (_, index) => (
            <RowStack key={index} flexWrap="nowrap">
              <ControlledTextField
                control={control}
                name={`value.version.urls.${index}`}
                required
              />

              {urlCount > 1 && index === 0 && <Box width={20} />}
              {index > 0 && (
                <IconButton
                  color="error"
                  onClick={() =>
                    setValue('value.version.urls', urls?.slice(0, index))
                  }
                >
                  <IconTrash />
                </IconButton>
              )}
            </RowStack>
          ))}
        </Stack>

        <Button
          onClick={() => setValue('value.version.urls', urls?.concat(''))}
          sx={{ marginTop: 2 }}
        >
          <FM defaultMessage="Add URL" />
        </Button>
      </Box>

      <ControlledTextField
        control={control}
        defaultValue=""
        label={<FM defaultMessage="SHA 256" />}
        maxRows={4}
        minRows={4}
        multiline
        name="value.version.sha256_sum"
        required
        variant="outlined"
      />

      <Box component="fieldset">
        <FormLabel component="legend" sx={{ marginBottom: 2 }}>
          <Typography
            color="text.secondary"
            component="legend"
            sx={{ marginBottom: 2, padding: 0 }}
            variant="h4"
          >
            <FM defaultMessage="Advanced Options" />
          </Typography>
        </FormLabel>

        <ControlledTextField
          control={control}
          label={<FM defaultMessage="Relative Toolchain Path" />}
          name="value.version.relative_tool_chain_path"
        />
      </Box>
    </Stack>
  );
};

const ToolVersionMapFields = () => {
  const { control } = useFormContext();

  return (
    <Stack gap={4} marginTop={1}>
      <ControlledTextField
        control={control}
        label={<FM defaultMessage="Package" />}
        name="value.key"
        required
      />

      <ControlledTextField
        control={control}
        label={<FM defaultMessage="Version" />}
        name="value.version.name"
      />
    </Stack>
  );
};

const ToolListFields = () => {
  const { control } = useFormContext();

  return (
    <Stack gap={4} marginTop={1}>
      <ControlledTextField
        control={control}
        label={<FM defaultMessage="Package" />}
        name="value.key"
        required
      />
    </Stack>
  );
};
