import { useNavigate } from '@tanstack/react-location';
import { useMemo, useState } from 'react';
import Shepherd from 'shepherd.js';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  useListFindings,
  useListProjects,
  useListVersionUpgrade,
  useSession,
} from '@endorlabs/queries';

import { StaleTimes } from '../../../constants';
import { useAuthTenantInfo, useEventTracking } from '../../../providers';
import { getOnboardRootPath, NamedRoutes } from '../../../routes';
import { getTourDataConfig } from '../constants/TourDataConfig';
import { ProductTourDefaultOptions } from '../constants/TourOptions';
import { getStepDefinition } from '../constants/TourSteps';
import { ProductTourData, TourSteps } from '../types';
import { onTourEnd, onTourStart } from '../utils';

export interface TourStateProps {
  tour: Shepherd.Tour;
  tourCompleted: boolean;
}

interface TourProps {
  getTourState: () => TourStateProps;
  setTourState: (props: TourStateProps) => void;
  productTour: Shepherd.Tour;
  tourData: ProductTourData;
  tourState: TourStateProps;
}

export const useProductTour = () => {
  const { trackUserEvent } = useEventTracking();
  const navigate = useNavigate();

  const { getLastUsedTenant } = useSession();
  const lastUsedTenant = getLastUsedTenant();
  const { isSharedTenant } = useAuthTenantInfo(lastUsedTenant);

  const [tourState, setTourState] = useState<{
    tour?: Shepherd.Tour;
    tourCompleted: boolean;
  }>({
    tour: undefined,
    tourCompleted: false,
  });

  const {
    assuredPackageVersionName,
    findingsProjectName,
    remediationsDependencyName,
    tourTenant,
    vulnFindingName,
  } = getTourDataConfig();

  // Get the project we'll be using
  const qFindingsProject = useListProjects(
    tourTenant,
    { staleTime: StaleTimes.EXTENDED },
    { filter: `meta.name==${findingsProjectName}` }
  );

  // Retrieve the findings we'll be using so we can identify them in the DOM
  const qRelevantFindings = useListFindings(
    tourTenant,
    { staleTime: StaleTimes.EXTENDED },
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.or([
          `(meta.description=="${vulnFindingName}")`,
        ]),
        filterExpressionBuilders.mainResourceContext(),
      ]),
      mask: 'uuid,meta,spec.summary',
    }
  );

  const qPatch = useListVersionUpgrade(
    tourTenant,
    {
      filter: `spec.upgrade_info.direct_dependency_package matches ${assuredPackageVersionName} and spec.upgrade_info.is_endor_patch == true`,
    },
    { staleTime: StaleTimes.EXTENDED }
  );

  const tourData = useMemo<ProductTourData>(() => {
    const findings = qRelevantFindings?.data?.list?.objects ?? [];

    return {
      assuredPackageVersionUuid: qPatch?.data?.objects[0]?.uuid,
      findingsProject: qFindingsProject?.data?.list?.objects[0] ?? undefined,
      remediationsDependencyName,
      lastUsedTenant,
      tourTenant,
      vulnFindingUuid: findings.find(
        (f) => f.meta.description === vulnFindingName
      )?.uuid,
    };
  }, [
    qRelevantFindings?.data?.list?.objects,
    qFindingsProject?.data?.list?.objects,
    qPatch,
    lastUsedTenant,
    tourTenant,
    remediationsDependencyName,
    vulnFindingName,
  ]);

  // Define tour steps by processing step definitions, providing access to additional functionality
  const productTourSteps = useMemo(() => {
    return TourSteps.map((stepId, i) => {
      return getStepDefinition({
        id: stepId,
        navigate,
        stepNumber: i,
        tourData,
      });
    });
  }, [navigate, tourData]);

  const productTour = useMemo(() => {
    const tour = new Shepherd.Tour(ProductTourDefaultOptions);
    const tenantName = tourData.lastUsedTenant;

    const returnToOnboarding = () => {
      const returnTo =
        tenantName && !isSharedTenant
          ? getOnboardRootPath({ tenantName })
          : NamedRoutes.TENANT_CREATE;

      navigate({ to: returnTo });
    };

    tour.on('start', function () {
      onTourStart(tour);

      if (tenantName) {
        trackUserEvent(tenantName, 'PRODUCT_TOUR', 'INITIATED');
      }
    });

    tour.on('cancel', function () {
      onTourEnd();
      returnToOnboarding();

      if (tenantName) {
        trackUserEvent(tenantName, 'PRODUCT_TOUR', 'ABORTED', {
          // Include the step when the user cancels a tour
          currentStep: tour.getCurrentStep()?.id as string,
        });
      }
    });

    tour.on('complete', function () {
      onTourEnd();

      returnToOnboarding();

      if (tenantName) {
        trackUserEvent(tenantName, 'PRODUCT_TOUR', 'COMPLETED');
      }
    });

    tour.addSteps(productTourSteps);

    return tour;
  }, [
    isSharedTenant,
    navigate,
    productTourSteps,
    tourData.lastUsedTenant,
    trackUserEvent,
  ]);

  return {
    getTourState: () => tourState,
    setTourState: (props: Partial<TourProps>) =>
      setTourState({ ...tourState, ...props, tour: productTour }),
    productTour,
    tourData,
    tourState,
  };
};
