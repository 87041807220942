// import theme for editor
import './codemirror-theme.css';

import type { EditorConfiguration, EditorFromTextArea } from 'codemirror';
import { Box, Stack } from '@mui/material';
import { noop as _noop } from 'lodash-es';
import { ReactNode, useEffect, useRef } from 'react';

import {
  CopyToClipboardButton,
  FileDownloadButton,
  FileDownloadButtonProps,
} from '../../../../components';
import { useLatestCallback } from '../../../../hooks';
import { TitleActionHeader } from '../../../layout';

const DEFAULT_EDITOR_OPTIONS: EditorConfiguration = {
  lineNumbers: true,
  lineWrapping: true,
  showCursorWhenSelecting: true,
  tabSize: 2,
};

type SupportedCodeEditorLanguage =
  | 'rego'
  | 'application/json'
  | 'text/x-diff'
  | 'text/x-endor-filter'
  | 'text/x-go'
  | 'text/x-yaml';

type EditorOptions = Omit<
  EditorConfiguration,
  | 'mode' // mode is inferred from language
  | 'value' // value is passed via props
>;

export interface CodeEditorProps {
  disableTitleTypography?: boolean;
  enableClipboard?: boolean;
  defaultValue?: string;
  downloadProps?: FileDownloadButtonProps;
  height?: number | string;
  editorOptions?: EditorOptions;
  language: SupportedCodeEditorLanguage;
  onChange?: (value: string | undefined) => void;
  onMount?: (editor: EditorFromTextArea) => void;
  title?: ReactNode;
  value?: string;
  width?: number | string;
  readOnly?: boolean;
}

/**
 * Embedded code editor powered by CodeMirror.
 * Allows full customization via editorOptions, but exposes most common options in props.
 *
 * @link https://codemirror.net/5/
 *
 * NOTE: using CodeMirror@5 due to issues with inline-styles and Content-Security-Policy
 * @link https://github.com/codemirror/dev/issues/395#issuecomment-1184868365
 */
export const CodeEditor = ({
  disableTitleTypography,
  enableClipboard = true,
  defaultValue = '',
  downloadProps,
  editorOptions,
  height = 240,
  language,
  onChange,
  onMount,
  value,
  width = 800,
  title,
  readOnly = false,
}: CodeEditorProps) => {
  const editorContainer = useRef<HTMLTextAreaElement | null>(null);
  const editor = useRef<EditorFromTextArea | null>(null);

  // Store the value in a ref and update it on change to have access
  // to the latest value in the closure scope of init()
  const valueRef = useRef<string | undefined>(value);

  const handleChange = useLatestCallback(onChange ?? _noop);

  // Initialize editor instance
  useEffect(
    () => {
      // exit if already initialized
      if (editor.current) return;

      // handle teardown on unmount, or if initialization fails
      const teardown = () => {
        if (editor.current) {
          editor.current.toTextArea();
          editor.current = null;
        }
      };

      const init = async () => {
        const CodeMirror = (
          await import(/* webpackChunkName: "vendor-codemirror" */ 'codemirror')
        ).default;
        await import(
          /* webpackChunkName: "vendor-codemirror" */ 'codemirror/mode/meta'
        );

        // handle language-specific imports
        if (language === 'rego') {
          await import(
            /* webpackChunkName: "vendor-codemirror" */ 'codemirror-rego/mode'
          );
        } else if (language === 'text/x-endor-filter') {
          await import(
            /* webpackChunkName: "vendor-codemirror" */ './mode/endor-filter'
          );
        } else if (language === 'text/x-diff') {
          await import(
            /* webpackChunkName: "vendor-codemirror" */ './mode/diff'
          );
        }

        // if the editor container is not present in the DOM, exit
        if (!editorContainer.current) return;

        const instance = CodeMirror.fromTextArea(editorContainer.current, {
          ...DEFAULT_EDITOR_OPTIONS,
          ...editorOptions,
          lineWrapping: false,
          mode: language,
          value: defaultValue,
          readOnly,
        });

        // set the initial value
        if (valueRef.current) {
          instance.setValue(valueRef.current);
        }

        // set the initial size
        instance.setSize(width, height);

        // handle changes
        instance.on('change', (_editor) => {
          handleChange(_editor.getValue());
        });

        editor.current = instance;

        if (onMount) {
          onMount(instance);
        }
      };

      init().catch(() => {
        teardown();
      });

      return () => teardown();
    },
    // Only initialize once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Handle updates for value
  useEffect(() => {
    // Update ref on value change
    valueRef.current = value;
    if (
      editor.current &&
      value !== undefined &&
      value !== editor.current.getValue()
    ) {
      editor.current.setValue(value);
    }
  }, [value]);

  // Handle updates for editor size
  useEffect(() => {
    if (editor.current) {
      editor.current.setSize(width, height);
    }
  }, [width, height]);

  const actions = (downloadProps || enableClipboard) && (
    <>
      {downloadProps && (
        <FileDownloadButton {...downloadProps} data={value} size="small" />
      )}
      {enableClipboard && (
        <CopyToClipboardButton size="small" value={value ?? ''} />
      )}
    </>
  );

  return (
    <Stack
      height="inherit"
      spacing={1}
      width={width}
      className="code-editor-container"
    >
      {(title || actions) && (
        <TitleActionHeader
          action={actions}
          disableTypography={disableTitleTypography}
          title={title}
          titleTypographyProps={{ variant: 'h6' }}
        />
      )}
      <Box
        component="textarea"
        height={height}
        ref={editorContainer}
        role="textbox"
        width={width}
      />
    </Stack>
  );
};
