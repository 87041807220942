import { PickDeep } from 'type-fest';

import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { DependencyMetadataResource } from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

export const isPatchedDependencyMetadata = (
  resource?: PickDeep<
    DependencyMetadataResource,
    'spec.dependency_data.patched'
  >
): boolean => {
  return resource?.spec.dependency_data?.patched === true;
};

export const isPatchedPackageVerson = (
  resource?: PickDeep<PackageVersionResource, 'meta.name'>
): boolean => {
  const { version } = UIPackageVersionUtils.parsePackageName(
    resource?.meta.name
  );

  return version.includes('-endor-');
};
