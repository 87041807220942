import { get as _get } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { V1SupportedVersion } from '@endorlabs/api_client';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

import { ToolInfoType } from '../../types';
import {
  getToolchainToolInfoDefinition,
  getToolchainToolInfoMatch,
} from '../../utils';
import { CUSTOM_VERSION_KEY } from './constants';
import {
  FormToolchainToolInfoProps,
  ToolchainToolInfoFieldValues,
  ToolchainToolInfoSupportedVersionOption,
} from './types';

export const getDefaultValues = ({
  base,
  masterToolChainProfile,
  tools,
}: Pick<
  FormToolchainToolInfoProps,
  'base' | 'masterToolChainProfile' | 'tools'
>) => {
  const toolDefinition = getToolchainToolInfoDefinition(base);
  const tool = {
    ...base,
    type: toolDefinition?.type ?? ToolInfoType.ToolChainVersion,
    value: {
      key: '',
      version: { name: '' },
    },
  } as ToolchainToolInfoFieldValues;

  // set value from current
  const current = getToolchainToolInfoMatch(tools, tool);
  if (current) {
    tool.value = current.value;
  }

  const supportedVersionsKey = toolDefinition?.supportedToolKey;
  const hasSupportedVersions = !!supportedVersionsKey;

  const supportedVersions = _get(
    masterToolChainProfile?.spec,
    [
      'os',
      base.os,
      'arch',
      base.arch,
      'toolchain',
      supportedVersionsKey as string,
      'versions',
    ],
    []
  ) as V1SupportedVersion[];

  const mapped: ToolchainToolInfoSupportedVersionOption[] = supportedVersions
    .filter((sv) => !!sv.version.name)
    .map((sv) => ({
      ...sv,
      key: sv.version.name as string,
      label: sv.version.name,
    }))
    .sort((a, b) => UIPackageVersionUtils.sortBySemanticVersion(a.key, b.key));

  // Add custom version option
  mapped.push({
    key: CUSTOM_VERSION_KEY,
    label: <FM defaultMessage="Custom" />,
    version: {},
  });

  let hasCurrentVersion = false;
  if (tool.type === ToolInfoType.ToolChainVersion) {
    const currentVersion = tool.value.version.name;
    if (currentVersion) {
      hasCurrentVersion = true;
      const isSupportedVersion = supportedVersions.some(
        (sv) => sv.version.name === currentVersion
      );

      tool.value.key = isSupportedVersion ? currentVersion : CUSTOM_VERSION_KEY;
    }
  }

  return {
    hasCurrentVersion,
    hasSupportedVersions,
    supportedVersions: mapped,
    tool,
  };
};
