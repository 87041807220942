import { Box, IconButton, Stack, Theme, useTheme } from '@mui/material';

import {
  CopyToClipboardButton,
  FormattedTypography,
  IconExternalLink,
  Link,
  RowStack,
  useStyles,
} from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

export interface CodeSnippetProps {
  code?: string;
  fileLocation?: string;
}

export const FindingDetailCodeSnippet = ({
  code,
  fileLocation,
}: CodeSnippetProps) => {
  const { space, palette } = useTheme();
  const sx = useStyles(styles);

  return (
    <Stack
      direction="column"
      border={`1px solid ${palette.design.grays[200]}`}
      sx={sx}
    >
      <Stack
        gap={space.xs}
        direction="row"
        justifyContent={'space-between'}
        borderBottom={`1px solid ${palette.design.grays[200]}`}
        alignItems="flex-start"
        padding={2}
      >
        <FormattedTypography variant="caption" text={fileLocation} />
        <RowStack gap={space.sm} alignItems="center" minWidth="max-content">
          <CopyToClipboardButton value={code ?? ''} />
          <Link to={fileLocation} target="_blank">
            <IconButton>
              <IconExternalLink />
            </IconButton>
          </Link>
        </RowStack>
      </Stack>

      <Box>
        <CodeEditor
          editorOptions={{
            lineNumbers: true,
            lineWrapping: false,
          }}
          enableClipboard={false}
          height="auto"
          language="text/x-yaml"
          value={code}
          width="100%"
        />
      </Box>
    </Stack>
  );
};

function styles({ palette }: Theme) {
  return {
    '.CodeMirror': {
      border: 'none',
      background: palette.design.grays[100],
    },
  };
}
