import { isEmpty as _isEmpty, map as _map, uniq as _uniq } from 'lodash-es';
import { useMemo } from 'react';

import { FindingPolicyInfoPolicyResult } from '@endorlabs/api_client';
import { FilterExpression } from '@endorlabs/filters';
import {
  sortParamBuilders,
  useListFindings,
  useListPackageVersions,
  useListProjects,
  useListSemgrepRules,
} from '@endorlabs/queries';
import { DataTablePaginator } from '@endorlabs/ui-common';

import { FilterState } from '../../filters';

const FindingsMask = [
  'meta',
  'spec.project_uuid',
  'spec.dependency_file_paths',
  'spec.finding_categories',
  'spec.finding_metadata.vulnerability',
  'spec.finding_tags',
  'spec.level',
  'spec.target_dependency_package_name',
  'spec.finding_metadata.source_policy_info',
  'tenant_meta',
  'uuid',
].join(',');

const packageVersionMask = ['meta', 'spec.ecosystem', 'uuid'].join(',');

export interface UseFindingsDataProps {
  enabled?: boolean;
  filterState?: FilterState;
  filterExpression: FilterExpression;
  namespace: string;
  paginator: DataTablePaginator;
}

export const useFindingsData = ({
  enabled = true,
  filterExpression,
  namespace,
  paginator,
}: UseFindingsDataProps) => {
  const qFindings = useListFindings(
    namespace,
    { enabled },
    {
      filter: filterExpression,
      mask: FindingsMask,
      sort: sortParamBuilders.ascendingBy('spec.level'),
      ...paginator.getListParameters(),
    }
  );

  const findings = useMemo(() => {
    return qFindings.data?.list?.objects ?? [];
  }, [qFindings.data]);

  // Get the filter expression for related PackageVersion objects
  const relatedPackageVersionsFilter = useMemo(() => {
    const uuids = [];

    for (const f of findings) {
      if (f.meta.parent_kind === 'PackageVersion' && f.meta.parent_uuid) {
        uuids.push(f.meta.parent_uuid);
      }
    }

    if (uuids.length === 0) {
      return;
    }

    const deduped = _uniq(uuids);
    return `uuid in ["${deduped.join('","')}"]`;
  }, [findings]);

  const qListPackageVersions = useListPackageVersions(
    namespace,
    { enabled: !!findings && !!relatedPackageVersionsFilter },
    {
      filter: relatedPackageVersionsFilter,
      mask: packageVersionMask,
    }
  );

  const packageVersions = useMemo(() => {
    return qListPackageVersions.data?.list?.objects ?? [];
  }, [qListPackageVersions.data]);

  //Required for SAST findings only
  const { projectsIds, sastRulesIds } = useMemo(() => {
    let projectUuids = [];
    const sastRuleUuids = [];
    for (const f of findings) {
      projectUuids = _uniq(
        _map(findings, 'spec.project_uuid').filter((uuid: string) =>
          Boolean(uuid)
        )
      );

      const sastFindingResults: FindingPolicyInfoPolicyResult[] =
        f.spec?.finding_metadata?.source_policy_info?.results ?? [];
      if (!_isEmpty(sastFindingResults)) {
        const ruleIds = sastFindingResults.map(
          (r: FindingPolicyInfoPolicyResult) =>
            r?.fields ? r.fields['SAST Rule ID'] : ''
        );
        sastRuleUuids.push(...ruleIds);
      }
    }

    return {
      projectsIds: projectUuids,
      sastRulesIds: _uniq(sastRuleUuids),
    };
  }, [findings]);

  const qFindingProject = useListProjects(
    namespace,
    { enabled: !!namespace && projectsIds.length > 0 },
    {
      filter: `uuid in ["${projectsIds.join('","')}"]`,
      mask: 'uuid,meta.name,spec.platform_source',
    }
  );

  const qSelectedSASTRules = useListSemgrepRules(
    namespace,
    {
      sort: { path: 'meta.name' },
      filter: `meta.name in ["${sastRulesIds.join('","')}"]`,
    },
    {
      enabled: !!namespace && sastRulesIds.length > 0,
    }
  );

  return {
    findings,
    packageVersions,
    isLoading:
      qFindings.isLoading ||
      qListPackageVersions.isLoading ||
      qFindingProject.isLoading ||
      qSelectedSASTRules.isLoading,
    projects: qFindingProject.data?.list?.objects ?? [],
    semgrepRules: qSelectedSASTRules.data?.objects ?? [],
  };
};
