import { getEnv } from '../../../constants';
import { getRemediationsPath } from '../../../routes';
import { BasicTourStepProps } from '../types';
import { waitForElementAvailable } from '../utils';

const ENV = getEnv();

export const getAssuredPackageVersionStep = ({
  navigate,
  tourData,
}: BasicTourStepProps) => {
  const selOverview = `.RemediationDetail-overview`;

  return {
    attachTo: {
      element: selOverview,
      on: 'right-start',
    },

    beforeShowPromise: async function () {
      navigate({
        to: `${getRemediationsPath({
          tenantName: tourData.tourTenant,
          uuid: tourData.assuredPackageVersionUuid,
        })}`,
      });

      await waitForElementAvailable(selOverview);
    },
    title: "Fix fast, even when it's hard, with Endor Patches",
    description:
      'These minimum viable security patches help reduce the risks associated with version upgrades.',
    highlightSelectors: [selOverview, '[role="heading"]'],

    urlLearn: `${ENV.URL_ENDOR_DOCS}/scan-with-endorlabs/scm-configuration-management`,
  };
};
