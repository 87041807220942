import { Grid, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { useListAssuredPackageVersions } from '@endorlabs/queries';
import {
  ButtonStack,
  PackageEcosystemIcons,
  Pip,
  RowStack,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';
import { useAuthInfo } from '../../../providers';
import { VersionUpgradeDisplay } from '../../Remediations';
import { AssuredPackageVersionDetailView } from './AssuredPackageVersionDetailView';
import { PatchConfigurationDialogButton } from './PatchConfigurationDialogButton';

export const AssuredPackageVersionDetailPage = ({
  packageVersionName,
}: {
  packageVersionName?: string;
}) => {
  const { activeNamespace } = useAuthInfo();
  const { space } = useTheme();

  const {
    label: packageName,
    ecosystem,
    version,
  } = UIPackageVersionUtils.parsePackageName(packageVersionName);
  const EcosystemIcon = PackageEcosystemIcons[ecosystem];

  const qListAssuredPackageVersions = useListAssuredPackageVersions(
    activeNamespace,
    {
      filter: `meta.name=="${packageVersionName}"`,
      mask: ['meta', 'spec'].join(','),
      page_size: 1,
    },
    { enabled: !!packageVersionName }
  );

  const assuredPackageVersion = qListAssuredPackageVersions.data?.objects.at(0);

  // Display as version upgrage if this is an endor patch
  const fromVersion = version.replace(/-endor-latest$/, '');
  const showVersionUpgrade = version !== fromVersion;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
      <Grid item>
        <PageHeader
          action={
            assuredPackageVersion && (
              <ButtonStack>
                <PatchConfigurationDialogButton
                  namespace={assuredPackageVersion.tenant_meta.namespace}
                />
              </ButtonStack>
            )
          }
          Icon={EcosystemIcon}
          title={
            <RowStack>
              <FM
                defaultMessage="Patch {packageName}"
                values={{ packageName }}
              />

              {showVersionUpgrade ? (
                <VersionUpgradeDisplay
                  fromVersion={fromVersion}
                  toVersion={version}
                  size="large"
                />
              ) : (
                <Pip
                  color="info"
                  label={version}
                  size="large"
                  variant="outlined"
                />
              )}
            </RowStack>
          }
        />
      </Grid>

      {/* Show details when AssuredPackageVersion exists */}
      {assuredPackageVersion && (
        <Grid item>
          <AssuredPackageVersionDetailView
            namespace={activeNamespace}
            packageVersionName={packageVersionName}
          />
        </Grid>
      )}
    </Grid>
  );
};
