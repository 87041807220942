/* eslint sort-keys: "error" */

import { defineMessages, MessageDescriptor } from 'react-intl';
import { StringKeyOf } from 'type-fest';

import { V1Language, V1ToolChains } from '@endorlabs/api_client';
import { ToolChainProfileOSKey } from '@endorlabs/endor-core/MasterToolChainProfile';
import { LanguageMessages } from '@endorlabs/ui-common';

export const OSLabelMessages: Record<ToolChainProfileOSKey, MessageDescriptor> =
  defineMessages({
    darwin: { defaultMessage: 'macOS' },
    linux: { defaultMessage: 'Linux' },
  });

export const ToolchainLabelMessages: Partial<
  Record<keyof V1ToolChains, MessageDescriptor>
> = {
  // Define toolchains that are associated with an existing language
  dotnet_tool_chain: LanguageMessages[V1Language.Csharp],
  golang_tool_chain: LanguageMessages[V1Language.Go],
  java_tool_chain: LanguageMessages[V1Language.Java],
  javascript_tool_chain: LanguageMessages[V1Language.Js],
  php_tool_chain: LanguageMessages[V1Language.Php],
  python_tool_chain: LanguageMessages[V1Language.Python],
  ruby_tool_chain: LanguageMessages[V1Language.Ruby],
  rust_tool_chain: LanguageMessages[V1Language.Rust],
  scala_tool_chain: LanguageMessages[V1Language.Scala],
  // Define additional toolchains
  ...defineMessages({
    android_tool_chain: { defaultMessage: 'Android' },
  }),
};

/**
 * The concatenated paths for Toolchain Tools
 */
type ToolchainToolPaths = {
  [K in StringKeyOf<V1ToolChains>]: `${K}.${StringKeyOf<
    Required<V1ToolChains>[K]
  >}`;
}[keyof V1ToolChains];

export const ToolchainToolLabelMessages: Partial<
  Record<ToolchainToolPaths, MessageDescriptor>
> = {
  // Define toolchain tools that are associated with an existing language
  // - NOTE: tools defined as `some_tool_some.version` will fallback to show the label for the toolchain

  // Define additional tools
  ...defineMessages({
    'android_tool_chain.command_line_tools_version': {
      defaultMessage: 'Command-line Tools',
    },
    'android_tool_chain.packages': { defaultMessage: 'Packages' },
    'java_tool_chain.gradle_version': { defaultMessage: 'Gradle' },
    'java_tool_chain.maven_version': { defaultMessage: 'Maven' },
    'javascript_tool_chain.nodejs_version': { defaultMessage: 'Node.js' },
    'javascript_tool_chain.npm_packages': { defaultMessage: 'npm package' },
    'javascript_tool_chain.pnpm_version': { defaultMessage: 'pnpm' },
    'javascript_tool_chain.yarn_version': { defaultMessage: 'yarn' },
    'php_tool_chain.composer_version': { defaultMessage: 'Composer' },
    'python_tool_chain.pip_packages': { defaultMessage: 'pip package' },
    'rust_tool_chain.mirai_version': { defaultMessage: 'MIRAI' },
  }),
};
