import { Box, Chip, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { Row, Table } from '@tanstack/react-table';
import { isNil as _isNil } from 'lodash-es';
import { ForwardedRef, forwardRef, MouseEvent, useMemo } from 'react';
import { FormattedList, FormattedMessage as FM } from 'react-intl';

import {
  DependencyMetadataReachabilityType,
  V1ScoreCategory,
} from '@endorlabs/api_client';
import { getNormalizedVersionRefs } from '@endorlabs/endor-core/Package';
import { compareFindingCounts } from '@endorlabs/queries';
import {
  BooleanIconDisplay,
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDrawerButton,
  DataTableProps,
  IconEndorLogo,
  IconGlobe,
  IconLock,
  IconMinus,
  NilDisplay,
  NumberDisplay,
  OverflowFormattedListDisplay,
  PackageNameDisplay,
  ReachabilityTypeDisplay,
  ResolutionErrorStatusIndicator,
  Status,
  StatusIndicator,
  UIEventUtils,
} from '@endorlabs/ui-common';

import { DependenciesTableColumnId, DependenciesTableRow } from './types';

export interface DependenciesTableProps
  extends Omit<DataTableProps<DependenciesTableRow>, 'columns'> {
  excludeMetrics?: boolean;
  includeColumns?: DependenciesTableColumnId[];
  isLoadingRelated?: boolean;
  onClickDetail?: (row?: DependenciesTableRow) => void;
  onClickResolutionErrors?: (row?: DependenciesTableRow) => void;
  showVersion?: boolean;
}

const getReachabilityTypeSortOrder = (
  reachability?: DependencyMetadataReachabilityType
) => {
  if (
    !reachability ||
    reachability === DependencyMetadataReachabilityType.Unknown ||
    reachability === DependencyMetadataReachabilityType.Unspecified
  ) {
    return 0;
  }

  return reachability === DependencyMetadataReachabilityType.Reachable ? 1 : -1;
};

const buildDependenciesTableColumns = ({
  excludeMetrics,
  includeColumns,
  isLoadingRelated,
  onClickDetail,
  onClickResolutionErrors,
  showVersion,
}: Omit<
  DependenciesTableProps,
  'data'
>): DataTableColumnDef<DependenciesTableRow>[] => {
  const activeColumns = new Set<DependenciesTableColumnId>(includeColumns);

  const columns: DataTableColumnDef<DependenciesTableRow>[] = [
    {
      accessorKey: 'resolutionErrors',
      cell: ({ getValue, row }) => (
        <ResolutionErrorStatusIndicator
          errors={getValue()}
          onClick={
            onClickResolutionErrors
              ? (event) => {
                  event.stopPropagation();
                  onClickResolutionErrors(row.original);
                }
              : undefined
          }
        />
      ),
      colType: ColTypes.STATUS_INDICATOR,
      hidden: !onClickResolutionErrors,
    },
    {
      accessorKey: 'hasApproximation',
      cell: ({ getValue, row }) => {
        const hasApproximation = getValue();

        if (!hasApproximation) {
          return <StatusIndicator status={Status.Success} />;
        }

        return (
          <StatusIndicator
            status={Status.PartialSuccess}
            tooltipNode={
              <>
                <Typography variant="h4" marginBottom={1}>
                  <FM defaultMessage="Approximation" />
                </Typography>

                <Typography variant="caption">
                  <FM defaultMessage="Package resolution is missing or incomplete for this dependency." />
                </Typography>
              </>
            }
          />
        );
      },
      colType: ColTypes.STATUS_INDICATOR,
      hidden: !activeColumns.has('hasApproximation'),
    },
    {
      accessorKey: 'name',
      cell: ({ getValue, row }) => (
        <Stack direction="row" gap={2} flexWrap="wrap">
          <PackageNameDisplay name={getValue()} showVersion={showVersion} />

          {row.original.hasPhantomDependency && (
            <Tooltip title={<FM defaultMessage="Phantom Dependency" />}>
              <Chip label={<FM defaultMessage="Phantom" />} size="small" />
            </Tooltip>
          )}
        </Stack>
      ),
      colType: ColTypes.PACKAGE,
      header: () => <FM defaultMessage="Dependency" />,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('versionRef'),
      accessorKey: 'versionRef',
      cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
      colType: ColTypes.VERSION,
      header: () => <FM defaultMessage="Version" />,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('unresolvedVersionRefs'),
      accessorKey: 'unresolvedVersionRefs',
      cell: ({ getValue, row }) => {
        const unresolvedVersionRefs = getValue() as string[] | undefined;

        if (!unresolvedVersionRefs?.length) {
          return <NilDisplay variant="text" />;
        }

        const normalized = getNormalizedVersionRefs(
          row.original.ecosystem,
          unresolvedVersionRefs
        );

        return <OverflowFormattedListDisplay value={normalized} maxItems={1} />;
      },
      colType: ColTypes.TEXT_LONG,
      header: () => <FM defaultMessage="Requested Version" />,
      // enableSorting: true, // TODO: support sorting by semver constraint
    },
    {
      hidden: !activeColumns.has('isDirectDependency'),
      accessorKey: 'isDirectDependency',
      cell: ({ getValue }) => {
        const value = getValue();
        if (value === true) {
          return <FM defaultMessage="Direct" />;
        }

        if (value === false) {
          return <FM defaultMessage="Transitive" />;
        }

        return (
          <Tooltip title={<FM defaultMessage="Dependency type is not known" />}>
            <Box>
              {/* HACK: NilDisplay does not forward ref from the Tooltip for the `text` variant */}
              <NilDisplay variant="text" />
            </Box>
          </Tooltip>
        );
      },
      header: () => <FM defaultMessage="Type" />,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('dependenciesCount'),
      accessorKey: 'dependenciesCount',
      cell: ({ getValue }) => {
        const count = getValue();
        // handle `undefined` value
        return _isNil(count) ? (
          <NilDisplay variant="text" />
        ) : (
          <NumberDisplay value={count} />
        );
      },
      header: () => <FM defaultMessage="Dependencies" />,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('dependentsCount'),
      accessorKey: 'dependentsCount',
      colType: ColTypes.NUMBER,
      header: () => <FM defaultMessage="Dependent Packages" />,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('namespace'),
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('isUtilized'),
      accessorKey: 'isUtilized',
      colType: ColTypes.BOOLEAN,
      header: () => <FM defaultMessage="Used" />,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('reachability'),
      accessorKey: 'reachability',
      cell: ({ getValue }) => <ReachabilityTypeDisplay value={getValue()} />,
      colType: ColTypes.BOOLEAN,
      header: () => <FM defaultMessage="Reachable" />,
      minSize: 96,
      size: 96,
      enableSorting: true,
      sortingFn: (a, b) => {
        const sortA = getReachabilityTypeSortOrder(a.original?.reachability);
        const sortB = getReachabilityTypeSortOrder(b.original?.reachability);

        return sortA - sortB;
      },
    },
    {
      cell: (t) => {
        const { isPatched, isPublic } = t.row.original;

        // Overload the visibility column if dependency is patched
        if (isPatched) {
          return (
            <Tooltip title="This dependency was patched with an Endor Patch.">
              <IconEndorLogo />
            </Tooltip>
          );
        }

        // Handle: nil/unknown visbility
        if (_isNil(isPublic)) {
          return (
            <Tooltip title={<FM defaultMessage="Unknown" />}>
              <IconMinus />
            </Tooltip>
          );
        }

        return (
          <BooleanIconDisplay
            value={isPublic}
            TruthyIconComponent={IconGlobe}
            FalsyIconComponent={IconLock}
            tooltip={
              isPublic ? (
                <FM defaultMessage="Public" />
              ) : (
                <FM defaultMessage="Private" />
              )
            }
          />
        );
      },
      colType: ColTypes.BOOLEAN,
      enableSorting: true,
      header: () => <FM defaultMessage="Visibility" />,
      hidden: !activeColumns.has('visibility'),
      id: 'visibility',
    },
    {
      hidden: !activeColumns.has('sourceCode'),
      accessorKey: 'sourceCode',
      cell: ({ getValue }) => (
        <BooleanIconDisplay value={getValue()} tooltip={getValue()} />
      ),
      colType: ColTypes.BOOLEAN,
      header: () => <FM defaultMessage="Source Available" />,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('licenseNames'),
      accessorKey: 'licenseNames',
      cell: ({ getValue }) => {
        if (isLoadingRelated) return <Skeleton width="100%" />;

        const licenseNames: string[] = getValue() ?? [];

        if (licenseNames.length === 0) {
          return <NilDisplay variant="text" />;
        }

        return <FormattedList type="unit" value={licenseNames} />;
      },
      header: () => <FM defaultMessage="License" />,
      enableSorting: true,
    },
    {
      hidden: excludeMetrics,
      accessorKey: V1ScoreCategory.Security,
      colType: ColTypes.SCORE,
      enableSorting: true,
    },
    {
      hidden: excludeMetrics,
      accessorKey: V1ScoreCategory.Activity,
      colType: ColTypes.SCORE,
      enableSorting: true,
    },
    {
      hidden: excludeMetrics,
      accessorKey: V1ScoreCategory.Popularity,
      colType: ColTypes.SCORE,
      enableSorting: true,
    },
    {
      hidden: excludeMetrics,
      accessorKey: V1ScoreCategory.CodeQuality,
      colType: ColTypes.SCORE,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('findingCounts'),
      accessorKey: 'findingCounts',
      colType: ColTypes.FINDING_COUNTS,
      header: () => <FM defaultMessage="Findings" />,
      enableSorting: true,
      sortingFn: (a, b) =>
        compareFindingCounts(
          a.original?.findingCounts,
          b.original?.findingCounts
        ),
    },
  ];

  // Show the actions
  if (onClickDetail) {
    columns.push({
      id: 'actions',
      cell: ({ row }) =>
        row.original && (
          <Box display="flex" justifyContent="end">
            <DataTableDrawerButton
              onClick={(event) => {
                event.stopPropagation();
                onClickDetail(row.original);
                if (!row.getIsSelected()) row.toggleSelected();
              }}
            />
          </Box>
        ),
      colType: ColTypes.ACTIONS,
    });
  }

  // HACK: removing columns marked as hidden
  return columns.filter((col) => !col.hidden);
};

/**
 * Table intended for displaying a list of Dependencies
 *
 * For first-party packages, prefer {@see PackageVersionsTable}
 */
export const DependenciesTable = forwardRef(function DependenciesTableComponent(
  {
    onClickDetail,
    includeColumns,
    isLoadingRelated,
    excludeMetrics,
    onClickResolutionErrors,
    showVersion,
    ...props
  }: DependenciesTableProps,
  ref: ForwardedRef<Table<DependenciesTableRow>>
) {
  const navigate = useNavigate();
  const columns = useMemo(
    () =>
      buildDependenciesTableColumns({
        excludeMetrics,
        includeColumns,
        isLoadingRelated,
        onClickDetail,
        onClickResolutionErrors,
        showVersion,
      }),
    [
      excludeMetrics,
      includeColumns,
      isLoadingRelated,
      onClickDetail,
      onClickResolutionErrors,
      showVersion,
    ]
  );

  function handleRowClick(
    row: DependenciesTableRow,
    rowRef: Row<DependenciesTableRow>,
    evt: MouseEvent
  ) {
    if (row.link) {
      return UIEventUtils.simulateLinkClick(row.link, evt, navigate);
    }

    if (onClickDetail) {
      if (!rowRef.getIsSelected()) rowRef.toggleSelected();
      return onClickDetail(row);
    }
  }

  /* User should select only one row at a time when displaying row detail. */
  const isSingleRowSelect = onClickDetail ? true : false;

  return (
    <DataTable
      {...props}
      ref={ref}
      columns={columns}
      onRowClick={handleRowClick}
      isSingleRowSelect={isSingleRowSelect}
    />
  );
});
