import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { Suspense, useCallback } from 'react';
import {
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
  useController,
  useFormContext,
} from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { EmptyState } from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

export type ControlledCodeEditorFieldProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render' | 'rules'
> &
  // `control` prop is required for this component, but may be replaced with
  // the use of `FormProvider` in the future.
  Required<Pick<ControllerProps<T>, 'control'>> & {
    codeEditorProps?: React.ComponentPropsWithoutRef<typeof CodeEditor>;
    format?: (value: PathValue<T, Path<T>>) => string;
    parse?: (value: string) => PathValue<T, Path<T>>;
    required?: boolean;
    readOnly?: boolean;
  };

const DEFAULT_CODE_EDITOR_PROPS: React.ComponentPropsWithoutRef<
  typeof CodeEditor
> = {
  language: 'text/x-go',
  title: '',
};

export const ControlledCodeEditorField = <T extends FieldValues>({
  codeEditorProps = DEFAULT_CODE_EDITOR_PROPS,
  control,
  format,
  name,
  parse,
  required,
  readOnly,
}: ControlledCodeEditorFieldProps<T>) => {
  const { space } = useTheme();

  const sx = styles(useTheme());

  const { formatMessage: fm } = useIntl();

  const { clearErrors, setError } = useFormContext<T>();

  const { field, fieldState } = useController({
    control,
    name,
    rules: required
      ? {
          required: {
            value: true,
            message: fm({ defaultMessage: 'This field is required' }),
          },
        }
      : undefined,
  });

  const fieldValue = format ? format(field.value) : field.value;
  const fieldError = fieldState.error?.message;

  const resetError = useCallback(() => {
    clearErrors(name);
  }, [clearErrors, name]);

  const updateHiddenInput = useCallback(
    (codeEditorValue?: string) => {
      try {
        if (codeEditorValue) {
          const parsedValue = parse ? parse(codeEditorValue) : codeEditorValue;
          field.onChange(parsedValue);
          field.onBlur();
          resetError();
        }
      } catch (e: any) {
        setError(name, {
          type: 'validate',
          message: `Invalid input: ${e?.message}`,
        });
      }
    },
    [field, name, parse, resetError, setError]
  );

  return (
    <Stack
      spacing={space.xs}
      sx={{
        lineBreak: 'anywhere',
      }}
    >
      <Suspense
        fallback={
          <EmptyState
            size="medium"
            title={<FM defaultMessage="Loading Editor …" />}
          />
        }
      >
        <CodeEditor
          {...codeEditorProps}
          onChange={updateHiddenInput}
          value={fieldValue}
          readOnly={readOnly}
        />
      </Suspense>
      {fieldError && (
        <Typography color="error" variant="caption">
          {fieldError}
        </Typography>
      )}
    </Stack>
  );
};

const styles = ({ palette }: Theme) => ({
  '.CodeMirror': {
    border: 'none',
    borderRadius: 'none',
    marginTop: '0px',
  },
  '.CodeMirror-gutter': {
    background: palette.background.default,
  },
  '.CodeMirror-scroll': {
    background: palette.background.default,
  },
  '.code-editor-container': {
    border: `1px solid ${palette.text.light}`,
    borderRadius: '4px',
  },
  '.code-editor-container > .MuiStack-root': {
    padding: '8px',
    borderBottom: `1px solid ${palette.text.light}`,
  },
});
